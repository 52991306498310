import React from "react";

export interface Props {
  children: React.ReactNode;
  href: string;
  className?: string;
  style?: object;
}

const Link: React.FC<Props> = (props) => {
  return (
    <a href={props.href} className={props.className} style={props.style}>
      {props.children}
    </a>
  );
};

export default React.memo(Link);
