import React, { useState } from "react";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Newsletter: React.SFC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!isEmpty(email)) {
        setLoading(true);
        await axios.post("https://asia-northeast1-takasu.cloudfunctions.net/newsletter/subscribe", {
          email,
        });
        setSubscribed(true);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      // TODO: Add error notification
    }
  };

  return (
    <div className="site-newsletter">
      <div className="container">
        <div className="text-center">
          <h3 className="h4 mb-2">Subscribe to our newsletter</h3>
          <p className="text-muted">Join our newsletter and never miss out on new stories and events in Takasu!</p>
          <div className="row">
            <div className="col-xs-12 col-sm-9 col-md-7 col-lg-5 ml-auto mr-auto">
              {subscribed ? (
                <div className="mt-2">
                  <h3 className="text-center">Thank you for subscribing!</h3>
                </div>
              ) : (
                <form onSubmit={onSubmit}>
                  <div className="input-group mb-3 mt-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      area-label="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={loading}
                    />
                    <span className="input-group-btn">
                      <button type="submit" className="btn btn-secondary" disabled={loading}>
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <span>Subscribe</span>}
                      </button>
                    </span>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
