import React, { useState } from "react";
import { Navbar, NavbarToggler, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import isEmpty from "lodash/isEmpty";
import { useFirebaseAuth } from "../utils/auth/hooks";
import Link from "../components/Link";

export interface HeaderProps {
  authUser?: {
    id: string;
    email: string;
    emailVerified: boolean;
  };
}

const Header: React.SFC<HeaderProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const firebaseAuth = useFirebaseAuth();

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
      <Navbar className="navbar-expand-md navbar-light bg-white absolute-top">
        <div className="container">
          <NavbarToggler className="order-2 order-md-1" onClick={toggle} />
          <Collapse className="order-3 order-md-2" isOpen={isOpen} navbar>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link href="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/events-and-festivals" className="nav-link">
                  Events &amp; Festivals
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/food-and-drink" className="nav-link">
                  Food &amp; Drink
                </Link>
              </li>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  More
                </DropdownToggle>
                <DropdownMenu right>
                  <Link href="/gomi-guide" className="dropdown-item">
                    Gomi Guide
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </Collapse>
          <Link href="/" className="navbar-brand mx-auto order-1 order-md-3">
            Takasu City
          </Link>
          <Collapse className="order-4 order-md-4" isOpen={isOpen} navbar>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link href="/community" className="nav-link">
                  Community
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/visit-takasu" className="nav-link">
                  Visit Takasu
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/about-takasu" className="nav-link">
                  About Takasu
                </Link>
              </li>
              <li className="nav-item">
                {!isEmpty(props.authUser) || !isEmpty(firebaseAuth.user) ? (
                  <Link href="/my-account" className="nav-link">
                    My Account
                  </Link>
                ) : (
                  <Link href="/sign-in-or-sign-up" className="nav-link">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
