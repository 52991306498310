import React from "react";
import Link from "../components/Link";

const Footer = () => {
  const getYear = (): number => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <footer className="site-footer bg-darkest">
      <div className="container">
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <Link href="/privacy-policy" className="nav-link">
              Privacy Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link href="/terms-and-conditions" className="nav-link">
              Terms
            </Link>
          </li>
          <li className="nav-item">
            <Link href="/contact-us" className="nav-link">
              Contact
            </Link>
          </li>
        </ul>
        <div className="copy">
          © takasucity.com {getYear()}
          <br />
          All rights reserved by <a href="https://www.petrichor.jp">Petrichor 株式会社</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
