import React, { Fragment } from "react";
import App from "next/app";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import isEmpty from "lodash/isEmpty";
import { ToastContainer } from "react-toastify";
import { config as fontawesomeConfig } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "react-toastify/dist/ReactToastify.min.css";
import sentry from "../utils/sentry";
import Header from "../components/Header";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import "../theme/styles.scss";

// See https://github.com/FortAwesome/react-fontawesome#integrating-with-other-tools-and-frameworks
// Tell Font Awesome to skip adding the CSS automatically since it's being imported above
fontawesomeConfig.autoAddCss = false;

const { captureException } = sentry();

class Application extends App {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      return { pageProps };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  authUser = () => {
    if (isEmpty(this.props.pageProps)) {
      return {};
    }
    const {
      pageProps: { AuthUserInfo },
    } = this.props;
    if (isEmpty(AuthUserInfo)) {
      return {};
    }
    const { AuthUser } = AuthUserInfo;
    if (isEmpty(AuthUser)) {
      return {};
    }
    return AuthUser;
  };

  render() {
    return (
      <Fragment>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <DefaultSeo
          titleTemplate="%s - 鷹栖町 Takasu City"
          openGraph={{
            type: "website",
            locale: "ja_JP",
            url: "https://www.takasucity.com/",
            site_name: "鷹栖町 Takasu City",
            description: "北海道鷹栖町から田舎生活の情報をお届け!",
          }}
          facebook={{
            appId: "1181531965529140",
          }}
        />
        <Header authUser={this.authUser()} />
        <main className="main pt-4">
          {this.state.hasError ? (
            <div className="row" style={{ paddingBottom: "15rem" }}>
              <div className="col-md-6 offset-md-3">
                <h1 className="text-center mt-5 mb-4">Sorry, we've encountered an unexpected error</h1>
                <div className="d-flex flex-column align-items-center">
                  {/*
                  <p>
                    <button type="button" onClick={() => Sentry.showReportDialog({ eventId: this.state.errorEventId })}>
                      Report this error
                    </button>
                  </p>
                  */}
                  <p>
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={() => {
                        window.location.reload(true);
                      }}
                    >
                      Try reloading the page
                    </button>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            super.render()
          )}
        </main>
        <Newsletter />
        <Footer />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default Application;
